export const TEXTS_RUSSIAN = {
  /* HEADER */
  header_navigation_link_services: 'Услуги',
  header_navigation_link_gallery: 'Галерея',
  header_navigation_link_team: 'Команда',
  header_navigation_link_contact: 'Контакт',
  header_navigation_active_language: 'РУC',
  header_address: 'Гусан Шерами 2',
  header_phone_number: '(+374) 93 577539',
  mobile_navigation_link_home: 'Начало',

  /* HERO */
  hero_headline: 'Паллиативная помощь с достоинством и добротой.',
  hero_description:
    'Центр «Хнами» оказывает паллиативную помощь пациентам с тяжелыми хроническими заболеваниями.',
  hero_cta: 'Записаться на бесплатную консультацию',

  /* SERVICES */
  services_headline: 'Услуги',
  service_1_headline: 'Условия',
  service_1_feature_1: 'Комфортные комнаты',
  service_1_feature_2: 'Отдельные санузлы',
  service_1_feature_3: 'ТВ и интернет',
  service_1_feature_4: 'Холодильник',
  service_2_headline: 'Ежедневный Уход',
  service_2_feature_1: 'Врачебный контроль',
  service_2_feature_2: '24/7 уход за больными',
  service_2_feature_3: '3-х разовое питание',
  service_2_feature_4: 'Гигиенические процедуры',
  service_3_headline: 'Лечение',
  service_3_feature_1: 'Симптоматическое лечение',
  service_3_feature_2: 'Обезболивание',
  service_3_feature_3: 'Централизованный кислород',
  service_3_feature_4: 'Профилактические процедуры',

  /* GALLERY */

  gallery_headline: 'Галерея',
  testimonial_1_text:
    'Профессиональный персонал, внимательное отношение. Вы делаете очень важную работу по улучшению качества жизни пациентов. Благодарю вас!',
  testimonial_1_author: 'Сируш Апресян',
  testimonial_2_text:
    'Хочу выразить благодарность коллективу Хнами Центра. У них замечательный, заботливый и профессиональный сервис.',
  testimonial_2_author: 'Ашот Енокян',
  testimonial_3_text:
    'Выражаю огромную благодарность всему коллективу Центра Хнами за вашу работу и профессионализм. Благодаря вам я вернулась к нормальной жизни!',
  testimonial_3_author: 'Ирина Каз',
  testimonial_4_text:
    'Большое спасибо за внимательное отношение и профессиональный подход! Отдельное спасибо директору Дианне за ее личное отношение.',
  testimonial_4_author: 'Анаит Агабабян',

  /* TEAM */
  team_headline: 'Наша команда',
  team_member_1_name: 'БЕГЛАРЯН Д.',
  team_member_1_role: 'РУКОВОДИТЕЛЬ ЦЕНТРА',
  team_member_2_name: 'АЙРАПЕТЯН Л.',
  team_member_2_role: 'АНЕСТЕЗИОЛОГ',
  team_member_3_name: 'МАЗМАНЯН В.',
  team_member_3_role: 'СЕМЕЙНЫЙ ДОКТОР',
  team_member_4_name: 'НЕРСИСЯН С.',
  team_member_4_role: 'СТАРШАЯ МЕДСЕСТРА',
  team_member_5_name: 'СУКИАСЯН К.',
  team_member_5_role: 'МЕДСЕСТРА',
  team_member_6_name: 'СИРЕКАНЯН Г.',
  team_member_6_role: 'МЕДСЕСТРА',
  team_member_7_name: 'Агаджанян А.',
  team_member_7_role: 'МЕДСЕСТРА',
  team_member_8_name: 'КОЧАРЯН С.',
  team_member_8_role: 'МЕДСЕСТРА',
  team_member_9_name: 'Атоян С.',
  team_member_9_role: 'СИДЕЛКА',
  team_member_10_name: 'ТАНТУШЯН Р.',
  team_member_10_role: 'СИДЕЛКА',
  team_member_11_name: 'Мкртчян А.',
  team_member_11_role: 'СИДЕЛКА',
  team_member_12_name: 'КАРАПЕТЯН Э.',
  team_member_12_role: 'СИДЕЛКА',

  /* CONTACT FORM */
  contact_form_headline: 'Запишитесь на бесплатную консультацию прямо сейчас!',
  contact_form_description:
    'Наша команда свяжется с вами в кратчайшие сроки и поможет организовать профессиональный уход за вашим близким.',
  contact_form_patient_name_label: 'Имя Пациента:',
  contact_form_patient_name_placeholder: 'Акоп Акопян',
  contact_form_patient_age_label: 'Возраст Пациента:',
  contact_form_patient_diagnosis_label: 'Диагноз Пациента:',
  contact_form_patient_diagnosis_placeholder: 'Рак',
  contact_form_contact_person_label: 'Контактное Лицо:',
  contact_form_contact_person_placeholder: 'Армине Акопян',
  contact_form_contact_number_label: 'Контактный Телефон:',
  contact_form_contact_mail_label: 'Почтовый Адрес:',
  contact_form_btn_label: 'Забронировать',
  contact_form_btn_loading_label: 'Отправка...',
  contact_form_success_response_title: 'Спасибо!',
  contact_form_success_response_message:
    'Форма успешно отправлена! Мы свяжемся с вами как можно скорее.',
  contact_form_error_response_title: 'Что-то пошло не так!',
  contact_form_error_response_message:
    'Пожалуйста, повторите попытку позже. Приносим извинения за неудобства.',

  /* COOPERATIONS */
  cooperations_headline: 'Мы сотрудничаем с',

  /* FOOTER */
  footer_headline_facebook: 'Подпишитесь на наш фейсбук!',
  footer_copyright: 'Центр Хнами',
  footer_headline_contact: 'Контакт',
  footer_address_line_1: 'Гусан Шерами 2, Ереван,',
  footer_address_line_2: '3-й этаж, здание Арменикум',
  footer_address_phone: '(+374) 93 577539',
  footer_address_email: 'contact@khnami-center.com',
  footer_btn_language: 'Язык',

  /* LEARN MORE */

  learn_more_headline_1: 'Наши задачи:',
  learn_more_paragraph_1:
    'Основными задачами паллиативного центра «Хнами» являются обеспечение возможно лучшего качества жизни больных, решение психологических, социальных и духовных проблем больного и родственников пациента.',
  learn_more_paragraph_2:
    'Сотрудники отделения осуществляют полноценное поддерживающее/паллиативное лечение по назначению лечащего врача с учетом особенностей заболевания, с упором на дезинтоксикационное лечение онкологических заболеваний и купирование побочных явлений.',
  learn_more_paragraph_3:
    'В спектр наших услуг входят внутривенные инфизии, переливание человеческого альбумина, крови или ее компонентов (тромбоцитов, плазмы), а также введение других необходимых лекарств, таких как антибиотики и противорвотные средства.',
  learn_more_paragraph_4:
    'Кислородная терапия проводится с помощью портативных кислородных концентраторов и централизованной системы подачи кислорода. Обезболивание и диетическая поддержка являются неотъемлемыми аспектами нашего ухода.',
  learn_more_headline_2: 'Отделение осуществляет следующие мероприятия:',
  learn_more_paragraph_5:
    '- оказание поддерживающей медицинской помощи больным в условиях, обеспечивающих круглосуточное медицинское наблюдение',
  learn_more_paragraph_6:
    '- адекватное обезболивание, в том числе посредством наркотических средств',
  learn_more_paragraph_7:
    '- проведение комплекса мероприятий по медицинской реабилитации больных',
  learn_more_paragraph_8:
    '- оказание психологической помощи больным и членам их семей на основе индивидуального подхода с учетом особенностей личности',
  learn_more_paragraph_9:
    '- осуществление ухода за пациентами, в том числе профилактика и лечение пролежней, уход за дренажами и стомами, кормление, включая зондовое питание',
  learn_more_paragraph_10:
    '- консультации и семинары для родственников, осуществляющих уход за больными при заболеваниях, требующих оказания паллиативной медицинской помощи',
  learn_more_paragraph_11:
    '- ведение учетной и отчетной документации, предоставление отчетов о деятельности в установленном порядке, сбор данных для регистров, ведение которых предусмотрено законодательством',
  learn_more_headline_3: 'При госпитализации пациентов необходимы:',
  learn_more_paragraph_12: '- Паспорт',
  learn_more_paragraph_13: '- Информированное согласие пациента',
  learn_more_paragraph_14: '- Выписка из истории болезни',
  learn_more_paragraph_15:
    'Длительность госпитализации пациентов определяется индивидуально в зависимости от состояния и тяжести заболевания. При госпитализации и при выписке транспортировка больных осуществляется родственниками самостоятельно.',
  learn_more_headline_4:
    'Противопоказания для госпитализации в отделение паллиативной медицинской помощи:',
  learn_more_paragraph_16:
    '- Инфекционные заболевания, требующие проведения специфического лечения в профильных отделениях',
  learn_more_paragraph_17:
    '- Психические заболевания, а также деменция и энцефалопатия III степени, требующие специфического лечения и наблюдения в профильных отделениях',
  learn_more_paragraph_18:
    '- Заболевания, требующие проведения интенсивного лечения: ИВЛ, диализ и т.д.',
  learn_more_paragraph_19:
    '- Несогласие пациента и/или его доверенного лица с принципами нахождения в отделении паллиативной медпомощи',
  learn_more_headline_5:
    'Отделение паллиативной медицинской помощи “Хнами” обеспечивает все условия для комфортного пребывания пациента:',
  learn_more_paragraph_20:
    '- Двухместные палаты, оборудованные функциональными кроватями, кнопкой вызова персонала, телевизором, холодильником',
  learn_more_paragraph_21:
    '- Постельное белье и бытовые принадлежности (мыло, полотенце, туалетная бумага и т.д)',
  learn_more_paragraph_22:
    '- Лабораторные исследования по необходимости (общий анализ крови, креатинин и мочевина в крови, глюкоза)',
  learn_more_paragraph_23:
    '- Предоставление калоприемников, мочеприемников, памперсов по необходимости',
  learn_more_paragraph_24: '- Трехразовое питание',
  learn_more_paragraph_25:
    '- Возможность круглосуточного нахождения родственника с пациентом',

  /* UI */
  ui_back_to_top_btn: 'Наверх',
  ui_learn_more_btn_expand: 'Узнать Более',
  ui_learn_more_btn_hide: 'Спрятать',

  /* IMAGE ALTS */
  hero_alt:
    'Хнами оказывает паллиативную помощь пациентам с хроническими заболеваниями',
  gallery_alt: 'Палата паллиативной помощи Ереван',
  team_alt: 'Участник команды',

  /* SEO */
  seo_document_title: 'Хнами — Паллиативный Центр',
  seo_meta_url: 'https://khnami-center.com/ru',
  seo_meta_description:
    'Центр «Хнами» оказывает паллиативную помощь и уход пожилому и взрослому населению Еревана и близлежащих регионов.',
  seo_meta_keywords:
    'паллиативная, паллиативная помощь, помощь, онкология, центр, больница',
  seo_og_description:
    'Мы оказываем паллиативную помощь пациентам с хроническими заболеваниями.',
};
