export const TEXTS_ARMENIAN = {
  /* HEADER */
  header_navigation_link_services: 'Ծառայություններ',
  header_navigation_link_gallery: 'Պատկերասրահ',
  header_navigation_link_team: 'Թիմ',
  header_navigation_link_contact: 'Կապ',
  header_navigation_active_language: 'ՀԱՅ',
  header_address: 'Գուսան Շերամի 2',
  header_phone_number: '(+374) 93 577539',
  mobile_navigation_link_home: 'Սկիզբ',

  /* HERO */
  hero_headline: 'Պալիատիվ խնամք՝ արժանավայել և լի բարությամբ',
  hero_description:
    'Խնամի կենտրոնը ցուցաբերում է պալիատիվ օգնություն ծանր քրոնիկ հիվանդներին',
  hero_cta: 'Ամրագրել անվճար խորհրդատվություն',

  /* SERVICES */
  services_headline: 'Ծառայություններ',
  service_1_headline: 'Պայմաններ',
  service_1_feature_1: 'Հարմարավետ սենյակներ',
  service_1_feature_2: 'Առանձին սանհանգույց',
  service_1_feature_3: 'TV և ինտերնետ',
  service_1_feature_4: 'Սառնարան',
  service_2_headline: 'Մասնագիտացված Խնամք',
  service_2_feature_1: 'Բժշկի հսկողություն',
  service_2_feature_2: '24/7 բուժքույրական խնամք',
  service_2_feature_3: '3 անգամյա սնունդ',
  service_2_feature_4: 'Հիգիենայի միջոցառումներ',
  service_3_headline: 'Բուժում',
  service_3_feature_1: 'Սիմպտոմատիկ բուժում',
  service_3_feature_2: 'Ցավազրկում',
  service_3_feature_3: 'Կենտրոնացված թթվածին',
  service_3_feature_4: 'Կանխարգելիչ միջոցառումներ',

  /* GALLERY */

  gallery_headline: 'Պատկերասրահ',
  testimonial_1_text:
    'Պրոֆեսիոնալ անձնակազմ, հոգատար վերաբերմունք։ Շատ կարևոր աշխատանք եք կատարում` հիվանդների կյանքի որակը բարձրացնելու համար։Շնորհակալ եմ։',
  testimonial_1_author: 'Սիրուշ Ապրեսյան',
  testimonial_2_text:
    'Ցանկանում եմ իմ երախտագիտությունը հայտնել Խնամի կենտրոնի աշխատակազմին: Նրանք ունեն հիանալի, հոգատար և պրոֆեսիոնալ սպասարկում։',
  testimonial_2_author: 'Աշոտ Ենոքյան',
  testimonial_3_text:
    'Իմ անսահման շնորհակալությունը Խնամի կենտրոնի ողջ անձնակազմին ձեր աշխատանքի և պրոֆեսիոնալիզմի համար։ Ձեր շնորհիվ ես վերադարձա բնականոն կյանքին։',
  testimonial_3_author: 'Իրինա Կազ',
  testimonial_4_text:
    'Անչափ շնորհակալ ենք հոգատար վերաբերմունք և պրոֆեսիոնալ մոտեցման համար։ Առանձնահատուկ շնորհակալություն տնօրեն Տիկին Դիաննային մարդ տեսակի համար։',
  testimonial_4_author: 'Անահիտ Աղաբաբյան',

  /* TEAM */
  team_headline: 'Մեր թիմը',
  team_member_1_name: 'ԲԵԳԼԱՐՅԱՆ Դ.',
  team_member_1_role: 'ԿԵՆՏՐՈՆԻ ՂԵԿԱՎԱՐ',
  team_member_2_name: 'ՀԱՅՐԱՊԵՏՅԱՆ Լ.',
  team_member_2_role: 'ԱՆԵՍԹԵԶԻՈԼՈԳ',
  team_member_3_name: 'ՄԱԶՄԱՆՅԱՆ Վ.',
  team_member_3_role: 'ԸՆՏԱՆԵԿԱՆ ԲԺԻՇԿ',
  team_member_4_name: 'ՆԵՐՍԻՍՅԱՆ Ս.',
  team_member_4_role: 'ԱՎԱԳ ԲՈՒԺՔՈՒՅՐ',
  team_member_5_name: 'ՍՈՒՔԻԱՍՅԱՆ Կ.',
  team_member_5_role: 'ԲՈՒԺՔՈՒՅՐ',
  team_member_6_name: 'ՍԻՐԵԿԱՆՅԱՆ Գ.',
  team_member_6_role: 'ԲՈՒԺՔՈՒՅՐ',
  team_member_7_name: 'Աղաջանյան Ա.',
  team_member_7_role: 'ԲՈՒԺՔՈՒՅՐ',
  team_member_8_name: 'ՔՈՉԱՐՅԱՆ Ս.',
  team_member_8_role: 'ԲՈՒԺՔՈՒՅՐ',
  team_member_9_name: 'Աթոյան Ս.',
  team_member_9_role: 'ԽՆԱՄՈՂ',
  team_member_10_name: 'ԹԱՆԹՈՒՇՅԱՆ Ռ.',
  team_member_10_role: 'ԽՆԱՄՈՂ',
  team_member_11_name: 'Մկրտչյան Ա.',
  team_member_11_role: 'ԽՆԱՄՈՂ',
  team_member_12_name: 'ԿԱՐԱՊԵՏՅԱՆ Է.',
  team_member_12_role: 'ԽՆԱՄՈՂ',

  /* CONTACT FORM */
  contact_form_headline: 'Ամրագրե՛ք Ձեր անվճար խորհրդատվությունը հենց հիմա',
  contact_form_description:
    'Մեր թիմը հնարավորինս շուտ կկապվի ձեզ հետ և կօգնի ձեզ կազմակերպել ձեր հարազատի մասնագիտացված խնամքը',
  contact_form_patient_name_label: 'Հիվանդի Անունը:',
  contact_form_patient_name_placeholder: 'Հակոբ Հակոբյան',
  contact_form_patient_age_label: 'Հիվանդի Տարիքը:',
  contact_form_patient_diagnosis_label: 'Հիվանդի Ախտորոշումը:',
  contact_form_patient_diagnosis_placeholder: 'Ուռուցք',
  contact_form_contact_person_label: 'Ձեր Անունը։',
  contact_form_contact_person_placeholder: 'Արմինե Հակոբյան',
  contact_form_contact_number_label: 'Ձեր Հեռախոսահամարը:',
  contact_form_contact_mail_label: 'Ձեր է-փոստը:',
  contact_form_btn_label: 'Ամրագրել',
  contact_form_btn_loading_label: 'Ուղարկվում է...',
  contact_form_success_response_title: 'Շնորհակալություն',
  contact_form_success_response_message:
    'Ձևը հաջողությամբ ուղարկվեց: Մենք կկապվենք Ձեզ հետ որքան հնարավոր է շուտ:',
  contact_form_error_response_title: 'Ինչ որ բան սխալ գնաց։',
  contact_form_error_response_message:
    'Խնդրում ենք փորձել մի փոքր ուշ. Ներողություն անհանգստության համար.',

  /* COOPERATIONS */
  cooperations_headline: 'Մենք համագործակցում ենք',

  /* FOOTER */
  footer_headline_facebook: 'Հետևեք մեր ֆեյսբուքին',
  footer_copyright: 'Խնամի Կենտրոն',
  footer_headline_contact: 'Կապ',
  footer_address_line_1: 'Գուսան Շերամի 2, Երևան,',
  footer_address_line_2: 'Արմենիկումի շենք, 3-րդ հարկ',
  footer_address_phone: '(+374) 93 577539',
  footer_address_email: 'contact@khnami-center.com',
  footer_btn_language: 'Լեզու',

  /* LEARN MORE */

  learn_more_headline_1: 'Մեր նպատակները`',
  learn_more_paragraph_1:
    '«Խնամի» պալիատիվ կենտրոնի հիմնական խնդիրներն են `ապահովել հիվանդների կյանքի հնարավորինս լավ որակը, հիվանդի և հիվանդի հարազատների հոգեբանական, սոցիալական և հոգևոր խնդիրների լուծումը:',
  learn_more_paragraph_2:
    'Կենտրոնի անձնակազմը տրամադրում է լիարժեք պահպանողական/պալիատիվ խնամք և բուժում, հաշվի առնելով հիվանդության առանձնահատկությունները, շեշտը դնելով դեզինտաքսիկացիոն բուժման և կողմնակի երևույթների կառավարման վրա:',
  learn_more_paragraph_3:
    'Կենտրոնի ծառայությունների շրջանակը ներառում է ներերակային միջամտություններ, ալբումինի, արյան կամ արյան բաղադրիչների (թրոմբոցիտներ, պլազմա) փոխներարկում և այլ անհրաժեշտ դեղամիջոցների, ինչպիսիք են հակաբիոտիկները և հակաէմետիկները:',
  learn_more_paragraph_4:
    'Թթվածնային թերապիան կատարվում է շարժական թթվածնի կոնցենտրատորների և թթվածնի մատակարարման կենտրոնացված համակարգի միջոցով: Ցավի կառավարումը և սննդային աջակցությունը մեր խնամքի անբաժանելի մասն են:',
  learn_more_headline_2:
    'Բաժանմունքն իրականացնում է հետևյալ գործողությունները`',
  learn_more_paragraph_5:
    '- հիվանդների համար պահպանողական բուժում և պալիատիվ խնամքի ապահովում՝ շուրջօրյա բժշկական հսկողության պայմաններում',
  learn_more_paragraph_6: '- նպատակահարմար ցավազրկում, թմրամիջոցներով ներառյալ',
  learn_more_paragraph_7:
    '- հիվանդների վերականգնման համար բժշկական միջոցառումների համալիրի իրականացում',
  learn_more_paragraph_8:
    '- հիվանդներին և նրանց ընտանիքներին անհատական մոտեցմամբ հոգեբանական օգնության ցուցաբերում, հաշվի առնելով անհատի առանձնահատկությունները',
  learn_more_paragraph_9:
    '- հիվանդների խնամք, ներառյալ պարկելախոցերի կանխարգելում և բուժում, դրենաժների և ստոմաների խնամք, կերակրում, զոնդային կերակրում',
  learn_more_paragraph_10:
    '- խորհրդատվություններ և սեմինարներ՝ պալիատիվ խնամք պահանջող հիվանդություններով տառապող հիվանդների հարազատների հետ',
  learn_more_paragraph_11:
    '- հաշվառման և հաշվետվական փաստաթղթերի վարում, գործունեության հաշվետվությունների տրամադրում սահմանված կարգով, տվյալների հավաքագրում ռեգիստրների համար, որոնց վարումը նախատեսված է օրենքով',
  learn_more_headline_3:
    'Հիվանդների հոսպիտալացման համար անհրաժեշտ է ներկայացնել`',
  learn_more_paragraph_12: '- անձնագիր',
  learn_more_paragraph_13: '- հիվանդի տեղեկացված համաձայնություն',
  learn_more_paragraph_14: '- քաղվածք բժշկական պատմությունից',
  learn_more_paragraph_15:
    'Հիվանդների հոսպիտալացման տևողությունը որոշվում է անհատապես՝ կախված հիվանդության վիճակից և ծանրությունից: Հոսպիտալացման և դուրս գրման ժամանակ հիվանդների տեղափոխումը կատարվում է հարազատների կողմից ինքնուրույն:',
  learn_more_headline_4:
    'Պալիատիվ բուժօգնության բաժանմունք ընդունվելու հակացուցումներն են՝',
  learn_more_paragraph_16:
    '- ինֆեկցիոն հիվանդություններ, որոնք պահանջում են հատուկ բուժում մասնագիտացված բաժանմունքներում',
  learn_more_paragraph_17:
    '- հոգեկան հիվանդություններ, ինչպես նաև III աստիճանի դեմենցիա և էնցեֆալոպաթիա, որոնք պահանջում են հատուկ բուժում և դիտարկվում մասնագիտացված բաժանմունքներում',
  learn_more_paragraph_18:
    '- հիվանդություններ, որոնք պահանջում են ինտենսիվ բուժում, ԹԱՕ, դիալիզ և այլն',
  learn_more_paragraph_19:
    '- հիվանդի կամ նրա կողմից վստահված անձի անհամաձայնությունը պալիատիվ խնամքի բաժանմունքում գտնվելու սկզբունքների հետ',
  learn_more_headline_5:
    '«Խնամի» պալիատիվ բուժօգնության բաժանմունքը ապահովում է բոլոր պայմանները հիվանդների հարմարավետության համար՝',
  learn_more_paragraph_20:
    '- ֆունկցիոնալ մահճակալներով, անձնակազմի կանչի կոճակով, հեռուստացույցով և սառնարանով կահավորած երկու տեղանոց հիվանդասենյակներ',
  learn_more_paragraph_21:
    '- անկողնային պարագաներ և կենցաղային իրեր (օճառ, սրբիչներ, զուգարանի թուղթ և այլն)',
  learn_more_paragraph_22:
    '- անհրաժեշտության դեպքում լաբորատոր հետազոտություններ (ընդհանուր արյան ստուգում, կրեատինին և միզանյութ արյան մեջ, գլյուկոզա)',
  learn_more_paragraph_23:
    '- անհրաժեշտության դեպքում, կոլոստոմի պայուսակների, միզամուղների, անձեռոցիկների ապահովում',
  learn_more_paragraph_24: '- երեք անգամյա սնունդ',
  learn_more_paragraph_25:
    '- հիվանդի բարեկամի համար՝ հիվանդի հետ շուրջօրյա գտնվելու հնարավորություն',

  /* UI */
  ui_back_to_top_btn: 'Վերադառնալ',
  ui_learn_more_btn_expand: 'Ավելին',
  ui_learn_more_btn_hide: 'Թաքցնել',

  /* IMAGE ALTS */
  hero_alt: 'Խնամի-ն ցուցաբերում է պալիատիվ խնամք ծանր քրոնիկ հիվանդներին',
  gallery_alt: 'Պալիատիվ խնամքի պալատ Երևան',
  team_alt: 'Թիմի անդամ',

  /* SEO */
  seo_document_title: 'Խնամի — Պալլիատիվ Կենտրոն',
  seo_meta_url: 'https://khnami-center.com/',
  seo_meta_description:
    'Խնամի կենտրոնը պալիատիվ օգնություն և խնամք է ցուցաբերում Երևանի և ՀՀ մարզերի տարեց և չափահաս բնակչությանը։',
  seo_meta_keywords:
    'պալիատիվ, խնամք, պալիատիվ խնամք, օգնություն, բժշկական, ուռուցքաբանություն, կենտրոն, հիվանդանոց',
  seo_og_description:
    'Մենք տրամադրում ենք պալիատիվ խնամք քրոնիկ հիվանդություններ ունեցող հիվանդներին։',
};
